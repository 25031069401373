<template>
  <div class="pay_status">
    <div class="pay_success flex-y align-center">
      <div class="pay_con flex align-center">
        <img src="../../assets/image/zhifushibaiicon.png" alt>
        <div>
          <p class="pay_con_top1">订单支付失败！</p>
          <p class="pay_con_top3">订单编号：22544887982 &nbsp;&nbsp;订单金额：￥1000.00</p>
        </div>
      </div>
      <p class="pay_success_info1">由于网络系统的原因，您暂时无法完成支付，请稍后再试。</p>
      <p class="pay_success_info3">
        <span @click="pay">重新支付</span>
        <span @click="$router.push('/')">返回首页</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
      pay() {
          this.$router.push(`/payStatus`)
      }
  }
}
</script>
<style lang="scss" scoped>
.pay_status {
  padding-top: 30px;
  .pay_con {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    margin-top: 40px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 26px;
    }
    &_top1 {
      font-size: 18px;
      color: #0db168;
    }
    &_top2 {
      font-size: 14px;
      color: #333;
      margin: 10px 0;
      i {
        color: red;
      }
    }
    &_top3 {
      font-size: 14px;
      line-height: 30px;
      color: #333;
      i {
        color: #ff9800;
      }
    }
    &_top4 {
      font-size: 18px;
      color: #999999;
      margin-top: 10px;
    }
  }
  .pay_success {
    &_info1 {
      text-align: center;
      font-size: 14px;
      color: #999999;
      margin: 20px auto;
    }
    &_info2 {
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      letter-spacing: 0px;
      color: #333;
      margin-bottom: 58px;
      &_one {
        display: inline-block;
        width: 40px;
        height: 18px;
        background-color: #0db168;
        text-align: center;
        color: #fff;
        line-height: 18px;
        font-size: 12px;
        margin-right: 13px;
      }
      &_two {
        color: #ff0036;
      }
      &_three {
        color: #0db168;
      }
    }
    &_info3 {
      text-align: center;
      margin: 0 47px 470px auto;
      width: 100%;
      max-width: 1200px;
      span {
        display: inline-block;
        width: 140px;
        height: 40px;
        line-height: 40px;
        background-color: #0db168;
        font-size: 15px;
        color: #ffffff;
        margin-right: 47px;
        cursor: pointer;
        &:nth-of-type(2){
            background: transparent;
            border: 1px solid #0db168;
            color:#0db168;
        }
      }
    }
  }
}
.time {
  color: orange;
}
</style>

